import * as React from 'react';

import Grid from '@mui/material/Grid2';
import {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {IAnswer, IRfpData} from './RequestsTypes';
import {acquireAccessToken} from '../../lib/auth/acquireAccessToken';
import Markdown from 'react-markdown';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Title from "../../components/title/title";

const Answer = styled(Paper)(({theme}) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const Question = styled(Paper)(({theme}) => ({
    backgroundColor: '#ffffff',
    ...theme.typography.h5,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#000000',
    }),
}));

const AnswersView: FC = () => {
    const {id} = useParams();

    const [rfpData, setRfpData] = useState<IRfpData>();

    const fetchRequests = async () => {
        await fetch(`/api/rfp-document-analysis/rfp-document-request/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await acquireAccessToken()}`,
            },
        }).then(resp => resp.json())
            .then(data => {
                setRfpData(data);
            });
    };

    useEffect(() => {
        fetchRequests();
    }, []);

    const answerGrid = (a: IAnswer) => {
        return (
            <React.Fragment>
                <Grid size={4}>
                    <Question>{a.questionText}</Question>
                </Grid>
                <Grid size={8}>
                    <Answer>
                        <Markdown>{a.answerText}</Markdown>
                    </Answer>
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Title>{rfpData?.name}</Title>
            <Grid container spacing={2}>
                {rfpData?.answers?.map(a => answerGrid(a))}
            </Grid>
        </React.Fragment>);
};

export default AnswersView;
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import Title from '../../components/title/title';
import {Button, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {acquireAccessToken} from "../../lib/auth/acquireAccessToken";
import {useNavigate} from "react-router-dom";

const NewRequestForm: FC = () => {
    const [files, setFiles] = useState<FileList | null>(null);
    const [name, setName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const submitRequest = async () => {
        const formData = new FormData();
        formData.append('RequestName', name);
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
        }
        await fetch('/api/rfp-document-analysis/upload-documents', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${await acquireAccessToken()}`,
            },
            body: formData,
        });
    }

    const handleSubmitClick = () => {
        setLoading(true);
        submitRequest().then(() => setLoading(false)).then(() => navigate('/requests'));
    };
    const handleFileChange = (event: any) => {
        setFiles(event.target.files);
    };
    const handleNameChange = (event: any) => {
        setName(event.target.value);
    };
    return (
        <Box sx={{'& .MuiTextField-root': {mb: 1, width: '50ch'}}}>
            <Title>
                New Request
            </Title>
            <form>
                <div>
                    <TextField type={'text'} id="name" label="RFP Name" variant="standard"
                               helperText="Required"
                               onChange={handleNameChange}
                    />
                </div>
                <div>
                    <TextField id="files" label="Files" variant="standard" type="file"
                               slotProps={{htmlInput: {multiple: true}}}
                               onChange={handleFileChange}/>
                </div>
                <div>
                    <Button variant="contained"
                            color="primary"
                            startIcon={<CloudUploadIcon/>}
                            aria-label="upload"
                            sx={{mt: 2}}
                            onClick={handleSubmitClick}
                            disabled={loading}>
                        Upload Files
                    </Button>
                </div>
            </form>
        </Box>
    );
};

export default NewRequestForm;
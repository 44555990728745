import React, {FC, useEffect, useState} from 'react';
import {ListItem, ListItemIcon, ListItemText, Paper, TableContainer} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {acquireAccessToken} from '../../lib/auth/acquireAccessToken';
import Title from '../../components/title/title';
import {IRfpData, IRfpDocument} from './RequestsTypes';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import TaskIcon from '@mui/icons-material/Task';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import useInterval from "../../components/hooks/interval";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

const RfpDocumentRequests: FC = () => {
    const [requests, setRequests] = useState([] as IRfpData[]);
    const [delay, setDelay] = useState<null | number>(5000);

    const fetchRequests = async () => {
        // const apiServer = process.env['services__rfp-copilot-api__https__0'];
        // await fetch(apiServer + '/api/rfp-document-analysis/rfp-document-request', {
        await fetch('/api/rfp-document-analysis/rfp-document-request', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await acquireAccessToken()}`,
            },
        }).then(resp => resp.json())
            .then(data => setRequests(data));
    };
    useEffect(() => {
        fetchRequests();
    }, []);

    useEffect(() => {
        // If there are no requests or no request in progress, stop polling
        if (!requests.some((request) => request.status === 'InProgress')) {
            setDelay(null);
        } else {
            setDelay(5000);
        }
    }, [requests]);

    useInterval(() => {
        if (requests.length !== 0) {
            fetchRequests();
        }
    }, delay);

    const handleDownload = (event: React.MouseEvent, request: IRfpData) => {
        event.stopPropagation();
        const markdownContent = `
# ${request.name}

## Documents
${request.rfpDocuments.map(doc => `- ${doc.originalFileName}`).join('\n')}

## Status
${request.status}

## Created At
${new Date(request.createdAt).toLocaleString()}

## Answers
${request.answers.map(answer => `### ${answer.questionText}\n${answer.answerText}`).join('\n\n')}
        `;
        const blob = new Blob([markdownContent], {type: 'text/markdown'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${request.name}.md`;
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <React.Fragment>
            <Title>
                Requests
            </Title>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>RFP name</TableCell>
                            <TableCell>Documents</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                        '& .MuiTableRow-root:hover': {
                            backgroundColor: 'primary.light',
                        },
                    }}>
                        {requests.map((row: IRfpData) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <Link href={`/request/${row.id}`} variant={'contained'}
                                          color={'black'} underline={'none'}>
                                        {row.id}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link href={`/request/${row.id}`} variant={'contained'}
                                          color={'black'} underline={'none'}>
                                        {row.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link href={`/request/${row.id}`} variant={'contained'}
                                          color={'black'} underline={'none'}>
                                        <List>
                                            {row.rfpDocuments.map((doc: IRfpDocument, index) => {
                                                return (
                                                    <React.Fragment key={doc.filePath}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <TaskIcon/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={doc.originalFileName}/>
                                                        </ListItem>
                                                        {index < row.rfpDocuments.length - 1 && <Divider/>}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </List>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link href={`/request/${row.id}`} variant={'contained'}
                                          color={'black'} underline={'none'}>
                                        {row.status === 'Completed' ?
                                            <CircularProgress variant="determinate" color={'success'} value={100}/> :
                                            <CircularProgress/>}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link href={`/request/${row.id}`} variant={'contained'}
                                          color={'black'} underline={'none'}>
                                        {new Date(row.createdAt).toLocaleString()}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant='contained'
                                        color={'info'}
                                        startIcon={<DownloadIcon/>}
                                        onClick={(event) => handleDownload(event, row)}
                                    >
                                        Download
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default RfpDocumentRequests;
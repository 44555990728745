import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/app';
import reportWebVitals from './reportWebVitals';
// MSAL imports
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from "@azure/msal-browser";

import './styles/index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {BrowserRouter, Router, Routes} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from './lib/auth/authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);
const theme = {
    colors: {
        primary: '#0070f3',
        bg: '#fff',
        text: '#333',
        grey: '#aaa',
    },
    fontSize: '16px',
};

msalInstance.initialize().then(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement
    );
    root.render(
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </BrowserRouter>
        </MsalProvider>
    );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

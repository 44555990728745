import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import {msalInstance} from "../../index"

export const acquireAccessToken = async (): Promise<string> => {
    const instance = msalInstance;
    const accounts = instance.getAllAccounts();
    const accessTokenRequest = {
        scopes: [process.env.REACT_APP_AD_CLIENT_SCOPE!],
        account: accounts[0],
    };
    return instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            return accessTokenResponse.accessToken;
        })
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
            return Promise.reject(error);
        });
}
import React, {FC, useEffect, useState, ChangeEvent} from 'react';
import {
    Button,
    TextField,
    Box,
    Paper,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    FormControlLabel,
    Switch
} from '@mui/material';
import {acquireAccessToken} from '../../lib/auth/acquireAccessToken';
import Title from '../../components/title/title';

interface AdditionalKnowledge {
    id?: string;
    knowledge: string;
    relevantForQuestions: string[];
    relevantForAllQuestions: boolean;
    createdBy?: string;
    createdAt?: string;
}

const AdditionalKnowledgePage: FC = () => {
    const [additionalKnowledge, setAdditionalKnowledge] = useState<AdditionalKnowledge[]>([]);
    const [newKnowledge, setNewKnowledge] = useState<string>('');
    const [relevantForAllQuestions, setRelevantForAllQuestions] = useState<boolean>(true);
    const [relevantForQuestions, setRelevantForQuestions] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    // TODO: Fix stupid hack because http-proxy-middleware does not work with for me atm
    const serviceUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
        'https://localhost:7066' : '';

    const fetchAdditionalKnowledge = async () => {
        setLoading(true);


        const response = await fetch(serviceUrl + '/api/additional-knowledge', {
            headers: {
                'Authorization': `Bearer ${await acquireAccessToken()}`,
            },
        });
        const data = await response.json();
        setAdditionalKnowledge(data);
        setLoading(false);
    };

    const handleNewKnowledgeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewKnowledge(event.target.value);
    };

    const handleRelevantForAllQuestionsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRelevantForAllQuestions(event.target.checked);
    };

    const handleRelevantForQuestionsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRelevantForQuestions(event.target.value);
    };

    const handleAddKnowledge = async () => {
        const relevantForQuestionsArray = relevantForQuestions.split(',').map(id => id.trim());
        const newKnowledgeData: AdditionalKnowledge = {
            knowledge: newKnowledge,
            relevantForQuestions: relevantForQuestionsArray,
            relevantForAllQuestions: relevantForAllQuestions,
        };

        await fetch(serviceUrl + '/api/additional-knowledge', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await acquireAccessToken()}`,
            },
            body: JSON.stringify(newKnowledgeData),
        });

        setNewKnowledge('');
        setRelevantForQuestions('');
        setRelevantForAllQuestions(true);
        fetchAdditionalKnowledge();
    };

    useEffect(() => {
        fetchAdditionalKnowledge();
    }, []);

    return (
        <Box sx={{'& .MuiTextField-root': {mb: 1, width: '50ch'}}}>
            <Title>Additional Knowledge</Title>
            {loading ? (
                <CircularProgress/>
            ) : (
                <List>
                    {additionalKnowledge.map((knowledge) => (
                        <ListItem key={knowledge.id}>
                            <Paper sx={{p: 2, width: '100%'}}>
                                <ListItemText
                                    primary={knowledge.knowledge}
                                    secondary={
                                        <>
                                            {!knowledge.relevantForAllQuestions && (
                                                <div>Relevant for
                                                    Questions: {knowledge.relevantForQuestions.join(', ')}</div>
                                            )}
                                            <div>{knowledge.relevantForAllQuestions ? 'Relevant for all Questions' : ''}</div>
                                            <div>Created
                                                by: {knowledge.createdBy} on {knowledge.createdAt ? new Date(knowledge.createdAt).toLocaleString() : ''}</div>
                                        </>
                                    }
                                />
                            </Paper>
                        </ListItem>
                    ))}
                </List>
            )}
            <Box sx={{ml: 2, mt: 4}}>
                <h3>Create additional Knowledge</h3>
                <form>
                    <div>
                        <TextField
                            label="New Knowledge"
                            variant="outlined"
                            multiline={true}
                            rows={6}
                            value={newKnowledge}
                            onChange={handleNewKnowledgeChange}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={relevantForAllQuestions}
                                    onChange={handleRelevantForAllQuestionsChange}
                                    color="primary"
                                />
                            }
                            label="Relevant for All Questions"
                        />
                    </div>
                    <div>
                        <TextField
                            label="Relevant For Questions (comma separated IDs)"
                            variant="outlined"
                            value={relevantForQuestions}
                            onChange={handleRelevantForQuestionsChange}
                            disabled={relevantForAllQuestions}
                        />
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{mt: 2}}
                            onClick={handleAddKnowledge}
                            disabled={loading}
                        >
                            Add Knowledge
                        </Button>
                    </div>
                </form>
            </Box>
        </Box>
    );
};

export default AdditionalKnowledgePage;
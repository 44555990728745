import {InteractionType, IPublicClientApplication} from '@azure/msal-browser';
import {MsalAuthenticationTemplate, MsalProvider} from '@azure/msal-react';
import {Route, Routes} from 'react-router-dom';
import {acquireAccessToken} from '../lib/auth/acquireAccessToken';
import React from 'react';
import Layout from '../components/layout/layout';
import RfpDocumentRequests from '../features/rfp-doc-requests/RfpDocumentRequests';
import Answers from '../features/rfp-doc-requests/Answers';
import NewRequestForm from '../features/new-request/NewRequestForm';
import AdditionalKnowledge from "../features/additional-knowledge/AdditionalKnowledge";


const App = () => (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Pages/>
    </MsalAuthenticationTemplate>
);

const Pages = () => (
    <Routes>
        <Route element={<Layout/>}>
            <Route index element={<NewRequestForm/>}/>
            <Route path="/requests" element={<RfpDocumentRequests/>}/>
            <Route path="/request/:id" element={<Answers/>}/>
            <Route path="/additional-knowledge" element={<AdditionalKnowledge/>}/>
        </Route>
    </Routes>
);


export default App;
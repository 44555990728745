import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import {drawerWidth} from "../layout/layout";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";
import {Link} from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';

export interface DrawerProps {
    toggleDrawer: () => void;
    open: boolean;
}

const DrawerStyled = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const Drawer = ({toggleDrawer, open}: DrawerProps) => (
    <DrawerStyled variant="permanent" open={open}>
        <Toolbar
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
            }}
        >
            <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon/>
            </IconButton>
        </Toolbar>
        <Divider/>
        <List component="nav">
            {mainListItems}
        </List>
    </DrawerStyled>
);


export const mainListItems = (
    <React.Fragment>
        <ListItemButton to="/" component={Link}>
            <ListItemIcon>
                <AddIcon/>
            </ListItemIcon>
            <ListItemText primary="New Request"/>
        </ListItemButton>
        <ListItemButton to="/requests" component={Link}>
            <ListItemIcon>
                <FormatListBulletedIcon/>
            </ListItemIcon>
            <ListItemText primary="Requests"/>
        </ListItemButton>
        <ListItemButton to="/additional-knowledge" component={Link}>
            <ListItemIcon>
                <SchoolIcon/>
            </ListItemIcon>
            <ListItemText primary="Knowledge"/>
        </ListItemButton>
    </React.Fragment>
);

export default Drawer;
